import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import robot from "./robot";
import talk from "./talk";
import main from "./main";
import position from "./position";
import public_synthesis from "./public/synthesis";
import public_chess_learns from "./public/chess_learns";

const reducer = combineReducers({
  robot,
  talk,
  main,
  position,
  public_synthesis,
  public_chess_learns,
});

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
