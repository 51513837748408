import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useSpeechSynthesis } from "react-speech-kit";

export default function Talk(props) {
  const dispatch = useDispatch();
  const { onEnd, text, cancelSpeech } = props;

  const [rate, setRate] = useState(1);
  const [pitch, setPitch] = useState(1);
  const [voice, setVoice] = useState(null);
  const [voices, setVoices] = useState([]);

  const { speak, cancel, speaking, supported } = useSpeechSynthesis({
    onEnd,
  });

  const { speaker } = useSelector((state) => state.talk);

  useEffect(() => {
    return () => cancel();
  }, []);

  useEffect(() => {
    if (cancelSpeech) {
      cancel();
    }
  }, [cancelSpeech]);

  useEffect(() => {
    if (window.speechSynthesis) {
      setTimeout(() => {
        const v = window.speechSynthesis.getVoices();
        setVoices(v);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (!voices.length || !speaker) return;

    const voice = voices.find((voice) => {
      if (voice.name == speaker.name && voice.lang === speaker.lang) {
        return true;
      }

      return false;
    });

    setVoice(voice);
  }, [speaker, voices]);

  useEffect(() => {
    if (!text || !voice) return;

    speak({ text, rate, pitch, voice });
  }, [text, rate, pitch, voice]);

  return <div></div>;
}
