import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "talk",
  initialState: {
    open: false,
    voice: null,
    voices: [],
    locale: "en",
    speaker: null,
  },
  reducers: {
    settingVoice: (state, action) => {
      state.voice = action.payload;
    },
    settingLocale: (state, action) => {
      state.locale = action.payload;
    },
    settingSpeaker: (state, action) => {
      state.speaker = action.payload;
      state.locale = action.payload.lang.split("-")[0].toLocaleLowerCase();
    },
    settingVoices: (state, action) => {
      state.voices = action.payload;
      state.speakers = action.payload.map((voice) => {
        return {
          key: voice.lang,
          source: voice,
          text: voice.name,
          value: voice,
        };
      });
    },
    openingVoiceSwitcher: (state) => {
      state.open = true;
    },
    closingVoiceSwitcher: (state, action) => {
      state.open = false;
    },
  },
});

export default slice.reducer;

const {
  settingVoice,
  settingVoices,
  settingLocale,
  settingSpeaker,
  openingVoiceSwitcher,
  closingVoiceSwitcher,
} = slice.actions;

export const setVoice = (data) => async (dispatch) => {
  dispatch(settingVoice(data));
};

export const setLocale = (data) => async (dispatch) => {
  dispatch(settingLocale(data));
};

export const setSpeaker = (data) => async (dispatch) => {
  dispatch(settingSpeaker(data));
};

export const setVoices = (data) => async (dispatch) => {
  dispatch(settingVoices(data));
};

export const openVoiceSwitcher = (data) => async (dispatch) => {
  dispatch(openingVoiceSwitcher());
};

export const closeVoiceSwitcher = (data) => async (dispatch) => {
  dispatch(closingVoiceSwitcher());
};
