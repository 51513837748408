import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import RobotIdle from "app/components/robot/idle";
import RobotTalking from "app/components/robot/talking";
import RobotSmile from "app/components/robot/smile";
import RobotRightHand from "app/components/robot/right-hand";
import RobotLeftHand from "app/components/robot/left-hand";

export default function Robot(props) {
  const { style } = props;
  const dispatch = useDispatch();

  const {
    type,
    dimensions: { width, height },
  } = useSelector((state) => state.robot);

  switch (type) {
    case "idle":
      return <RobotIdle width={width} height={height} style={style} />;
      break;
    case "smile":
      return <RobotSmile width={width} height={height} style={style} />;
      break;
    case "talking":
      return <RobotTalking width={width} height={height} style={style} />;
      break;
    case "right-hand":
      return <RobotRightHand width={width} height={height} style={style} />;
      break;
    case "left-hand":
      return <RobotLeftHand width={width} height={height} style={style} />;
      break;
    default:
      return <RobotIdle width={width} height={height} style={style} />;
  }
}
