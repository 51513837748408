export function inferType(instance) {
    var _a, _b, _c;
    if (instance === null) {
        return "null";
    }
    const type = typeof instance;
    if (type !== "object") {
        return type;
    }
    return ((_c = (_b = (_a = instance) === null || _a === void 0 ? void 0 : _a.constructor) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.toLowerCase()) || "object";
}
