import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import store from "app/store";
import config from "app/config";
import Routes from "app/routes";
import Main from "app/screens/main";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  );
}

export default App;
