import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./index.css";

import I18n from "app/utils/i18n-js";
import { getCookie } from "app/cookie";
import { en, es, ru, uz } from "app/translations";

import App from "./app";
import reportWebVitals from "./reportWebVitals";

I18n.fallbacks = true;
I18n.translations = { en, es, ru };
I18n.locale = getCookie("locale") || "en";

Sentry.init({
  dsn:
    "https://41258867600e4bbc81657dffbf842f5a@o1023451.ingest.sentry.io/5989760",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
