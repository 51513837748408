import React, { useState } from "react";

import Lottie from "react-lottie";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";
import { Turn as Hamburger } from "hamburger-react";
import { Button, Grid } from "semantic-ui-react";
import useDimensions from "react-use-dimensions";

import Math from "./math";
import Chess from "./chess";

import winners from "app/assets/lottie/winners-lottie-animation.json";
import readyToLearn from "app/assets/lottie/ready-to-learn.json";
import dottedRings from "app/assets/lottie/dotted-rings-loading.json";
import brainBulb from "app/assets/lottie/brain-bulb-charging.json";
import aiLottie from "app/assets/lottie/ai-mk5.json";

import logo from "app/assets/images/logo.png";
import logoExpanded from "app/assets/images/logo-expanded.png";

// <Hamburger toggled={open} toggle={setOpen} />
import config from "app/config";

import background from "app/assets/images/background.png";

export default function Main() {
  const [open, setOpen] = useState(false);
  const [ref, { x, y, width }] = useDimensions();

  return (
    <div
      ref={ref}
      style={{
        height: "1000px",
        background: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          padding: 15,
          margin: 0,
        }}
      >
        <div style={{ padding: 0, marginBottom: 0 }}>
          <NavWrapper>
            {width > 500 ? (
              <LogoExpanded src={logoExpanded} />
            ) : (
              <Logo src={logo} />
            )}

            <MainNav>
              <ul>
                <li>
                  <ActionLink href={`${config.app_url}/login`}>
                    Sign in
                  </ActionLink>
                </li>
                <li>
                  <ActionLink href={`${config.app_url}/signup`}>
                    Sign up
                  </ActionLink>
                </li>
              </ul>
            </MainNav>
          </NavWrapper>
        </div>
        <div style={{ marginBottom: 40 }}>
          <Grid columns={2} stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <MainContentWrapper>
                  <Content>
                    <div className="main-parents-text">
                      Your personal digital teacher.
                    </div>
                    <div className="main-parents-description">
                      <span>
                        <span>
                          We help parents by automating all daily tasks.
                        </span>
                        <span
                          style={{
                            marginLeft: 10,
                            marginRight: 5,
                            fontWeight: 400,
                            color: "#457491",
                          }}
                        >
                          AI
                        </span>
                        <span style={{ position: "relative" }}>
                          <Lottie
                            speed={1}
                            style={{
                              width: 40,
                              height: 40,
                              position: "absolute",
                              left: 0,
                              top: 0,
                            }}
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: aiLottie,
                            }}
                            isPaused={false}
                            isStopped={false}
                            isClickToPauseDisabled
                          />
                        </span>

                        <span style={{ marginLeft: 40 }}>
                          generates questions, quizzes and assigns to
                        </span>
                      </span>
                      <span className="students">students.</span>
                      <span>Answers are auto verified</span>.
                    </div>
                  </Content>

                  <JoinWrapper>
                    <Button
                      color="teal"
                      size="huge"
                      onClick={() => {
                        window.location = `${config.app_url}/signup`;
                      }}
                    >
                      JOIN NOW
                    </Button>
                  </JoinWrapper>
                </MainContentWrapper>
              </Grid.Column>
              <Grid.Column>
                <Math />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div style={{ marginTop: 160, marginBottom: 40 }}>
          <Grid columns={2} stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <MainContentWrapper>
                  <Content>
                    <div className="main-parents-text">I know chess too.</div>
                  </Content>
                </MainContentWrapper>
              </Grid.Column>
              <Grid.Column>
                <Chess />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="I Accept"
          cookieName="cookie-consent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: 2 }}
          expires={150}
        >
          We use cookies and other tracking technologies to improve your
          browsing experience on our website, to analyze our website traffic,
          and to understand where our visitors are coming from.
        </CookieConsent>
      </div>
    </div>
  );
}

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (hover: hover) and (pointer: fine) {
    a:hover::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
`;

const Logo = styled.img`
  width: 56px;
  height: 56px;
`;

const LogoExpanded = styled.img`
  width: 214px;
  height: 60px;
`;
const MainNav = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    margin: 0px;
    padding: 0px;
    align-items: center;

    li {
      list-style: none;
      margin-right: 20px;
    }
  }
`;

const ActionLink = styled.a`
  font-size: 17px;
  font-weight: 500;
  color: #8d8d8d;
  display: block;
  -webkit-transition: 0.5s;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: black;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: red;
    transition: width 0.6s cubic-bezier(0.35, 1, 0.5, 1);
  }
`;

const MainContentWrapper = styled.div`
  padding: 40px;

  @media only screen and (max-width: 1000px) {
    padding: 10px;
  }
`;

const JoinWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: 600px) {
    justify-content: flex-end;
  }
`;

const Content = styled.div`
  margin-bottom: 40px;

  .main-parents-text {
    font-weight: bold;
    font-size: 64px;
    line-height: 1.09em;
    color: #403f40;
    margin-bottom: 20px;
  }

  .main-parents-description {
    font-size: 30px;
    line-height: 1em;
    word-break: break-all;

    .students {
      margin: 0px 4px;
      color: #ea5252;
    }
    .teachers {
      color: #ea5252;
    }
  }

  @media only screen and (max-width: 1000px) {
    .main-parents-text {
      font-size: 46px;
    }
  }
`;
