import React from "react";

import Lottie from "react-lottie";
import robot from "app/assets/lottie/robot-smile.json";

export default function Robot(props) {
  const { style, height, width } = props;

  return (
    <Lottie
      speed={1}
      style={{ ...style }}
      options={{
        loop: true,
        autoplay: true,
        animationData: robot,
      }}
      width={width}
      height={height}
      isStopped={false}
      isPaused={false}
      isClickToPauseDisabled
    />
  );
}
