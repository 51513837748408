import { I18n } from "i18n-js";

import { en, ru, uz } from "../translations";

const i18n = new I18n({
  en,
  ru,
});

// Set the locale once at the beginning of your app.
i18n.enableFallback = true;
i18n.locale = "en";

export default i18n;
