import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { get } from "app/api";
import { handleMeResponse, handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "public_chess_learns",
  initialState: {
    chess_learn: {
      solution: {},
      chess_learn_sections: [],
    },
    getting: false,
    validations: {},
  },
  reducers: {
    gettingChessLearn: (state, action) => {
      state.getting = true;
    },
    gotChessLearn: (state, action) => {
      state.getting = false;
      state.chess_learn = action.payload;
    },
    handleValidations: (state, action) => {
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const { gotChessLearn, gettingChessLearn, handleValidations } = slice.actions;

export const getChessLearn = (slug) => async (dispatch, getState) => {
  try {
    dispatch(gettingChessLearn());

    const response = await get({
      endpoint: `/api/v1/public/chess_learns/${slug}`,
    });

    dispatch(gotChessLearn(response.data.chess_learn));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};
