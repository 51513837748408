import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "position",
  initialState: { position: { x: 0, y: 0 } },
  reducers: {
    settingLessonPosition: (state, action) => {
      state.position = action.payload;
    },
  },
});

export default slice.reducer;

const { settingLessonPosition } = slice.actions;

export const setLessonPosition = (data) => async (dispatch) => {
  dispatch(settingLessonPosition(data));
};
