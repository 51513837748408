import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { Button, Segment } from "semantic-ui-react";

import Talk from "app/components/Talk";
import Robot from "app/components/MovingRobot";
import VoiceSlideSelector from "app/components/VoiceSlideSelector";

import { useWindowDimensions } from "app/hooks";
import { fetchSynthesis } from "app/store/public/synthesis";

import {
  setType,
  setPlaying,
  setScale,
  setAnimate,
  setPosition,
  setLocation,
  setDimentions,
  setMultiplier,
} from "app/store/robot";

const itemVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const listVariants = {
  open: {
    opacity: 1,
    transition: {
      staggerDirection: -1,
      staggerChildren: 0.2,
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.2,
    },
  },
};

export default function MathScreen(props) {
  const refs = useRef({});
  const mathRef = useRef();
  const answerRef = useRef();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const [question, setQuestion] = useState([]);
  const [explain, setExplain] = useState(false);

  const { locale, speaker } = useSelector((state) => state.talk);
  const { synthesis, fetching } = useSelector(
    (state) => state.public_synthesis
  );

  const currentIndexColor = "#4364af";
  const [currentText, setCurrentText] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [style, setStyle] = useState({
    opacity: 1,
    display: "flex",
    color: "#13a4a8",
    marginBottom: 2,
    justifyContent: "flex-end",
    textAlign: "center",
  });

  const [item, setItem] = useState({
    color: "#13a4a8",
    width: 33.3,
  });

  const [position, setPosition] = useState();
  const [currentTimeout, setCurrentTimeout] = useState();
  const [hydrating, setHydrating] = useState(false);

  const [steps, setSteps] = useState([...synthesis.steps]);
  const [answers, setAnswers] = useState([...synthesis.answers]);
  const [questions, setQuestions] = useState([...synthesis.questions]);
  const [remainders, setRemainders] = useState([...synthesis.remainders]);

  useEffect(() => {
    if (!explain) {
      dispatch(setAnimate({ x: 0, y: 0 }));
      dispatch(setLocation("bottom-left"));
      dispatch(setDimentions({ width: 55, height: 55 }));
    }
  }, [explain]);

  useEffect(() => {
    if (!fetching) return;

    setCurrentStep(null);
    setCurrentIndex(null);
    setCurrentText(null);

    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }
  }, [fetching]);

  useEffect(() => {
    if (!question.length) return;
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }

    setCurrentStep(null);
    setCurrentIndex(null);
    setCurrentText(null);
    setHydrating(true);

    dispatch(fetchSynthesis({ type: "add_numbers", question, locale }));
  }, [speaker, locale, question]);

  useEffect(() => {
    randomAddition();
  }, []);

  useEffect(() => {
    hydrate();
  }, [synthesis]);

  useEffect(() => {
    if (!explain || currentStep || hydrating || !steps.length) return;

    const step = steps.shift();

    setCurrentStep(step);
    setSteps(steps);

    runStep(step);
  }, [explain, steps, currentStep, hydrating]);

  function hydrate() {
    setSteps([...synthesis.steps]);
    setAnswers([...synthesis.answers]);
    setQuestions([...synthesis.questions]);
    setRemainders([...synthesis.remainders]);
    setHydrating(false);
  }

  function runStep(step) {
    if (step.action === "talk") {
      setCurrentText(step.message);
      dispatch(setType("talking"));
      return;
    }

    if (step.action === "end_lesson") {
      dispatch(setType("idle"));
      return;
    }

    const t = setTimeout(() => {
      dispatch(setType("left-hand"));

      if (step.action === "set_align_dots") {
        setQuestions(
          questions.map((question) => {
            return question.map((q) => {
              return { ...q, display: "block" };
            });
          })
        );
      } else if (step.action === "set_show_zeros") {
        setQuestions(
          questions.map((question) => {
            return question.map((q) => {
              if (q.action == "set_show_zeros") {
                q.visibility = "visible";
                return { ...q, display: "block", visibility: "visible" };
              }

              return { ...q, display: "block" };
            });
          })
        );
      } else if (step.action === "set_answer") {
        const answer = answers[currentIndex];

        setAnswers([
          ...answers.map((a) => {
            if (a === answer) {
              return { ...answer, visibility: "visible" };
            }

            return a;
          }),
        ]);
      } else if (step.action === "start_step") {
        setCurrentIndex(answers.length - 1);
      } else if (step.action === "next_step") {
        setCurrentIndex(currentIndex - 1);
      } else if (step.action === "end_step") {
        setCurrentIndex(null);
        dispatch(setType("talking"));
        dispatch(setType("idle"));
        dispatch(setAnimate({ x: 0, y: 0 }));
      } else if (step.action === "set_remainder") {
        let skip = false;

        const reversed = remainders
          .slice()
          .reverse()
          .map((r) => {
            if (!r.value || r.visibility == "visible" || skip) return r;

            skip = true;

            return { ...r, visibility: "visible" };
          });

        setRemainders(reversed.reverse());
      }

      setCurrentStep(null);
    }, 1000);

    setCurrentTimeout(t);
  }

  // useEffect(() => {
  //   if (!mathRef.current) return;
  //
  //   setPosition(mathRef.current.getBoundingClientRect());
  // }, [mathRef]);

  useEffect(() => {
    if (!Object.keys(refs).length) return;

    const ref = refs.current[`answer-${currentIndex}`];

    if (!ref) return;

    const { x, y } = ref.getBoundingClientRect();

    dispatch(setAnimate({ x: ref.offsetLeft - 45, y: ref.offsetHeight - 40 }));
    // dispatch(setAnimate({ x: width - x + 90, y: y - 20 }));
  }, [refs, currentIndex]);

  function randomAddition() {
    const randomDecimal = Math.round(Math.random());
    const decimals = [10, 100];
    const firstNumber =
      parseInt(Math.random() * 1000) / decimals[randomDecimal];
    const secondNumber = parseInt(Math.random() * 1000) / 10;

    setQuestion([firstNumber, secondNumber]);
  }

  return (
    <div ref={mathRef}>
      <Talk
        text={currentText}
        cancelSpeech={fetching || !explain}
        onEnd={() => {
          setCurrentText(null);
          setCurrentStep(null);
        }}
      />
      <ExplainWrapper>
        <LanguageSelect>
          <Segment
            style={{
              margin: 0,
              padding: 0,
              position: "sticky",
              top: 0,
              zIndex: 10,
            }}
          >
            <VoiceSlideSelector />
          </Segment>
        </LanguageSelect>

        <BlackBoard>
          <EquationWrapper>
            <Equation>
              <MathDigit>{question[0]}</MathDigit>
              <MathSign>+</MathSign>
              <MathDigit>{question[1]}</MathDigit>
              <MathSign>=</MathSign>
              <MathSign>?</MathSign>
            </Equation>
            <Button
              basic
              primary
              circular
              icon="random"
              style={{ alignSelf: "flex-start", margin: 6 }}
              onClick={randomAddition}
            />
          </EquationWrapper>

          {explain && (
            <AnswerWrapper>
              <div style={{ alignItems: "center", color: "#80437b" }}>+</div>
              <div>
                <motion.div
                  key="remainders"
                  initial={"closed"}
                  // exit={"closed"}
                  animate="open"
                  variants={listVariants}
                  transition={{ duration: 1 }}
                  style={{ ...style, position: "relative", bottom: -13 }}
                >
                  {remainders.map((num, index) => (
                    <motion.span
                      key={`remainders-${index}`}
                      variants={itemVariants}
                      style={{
                        ...item,
                        visibility: num.visibility,
                        display: num.display,
                        fontSize: "22px",
                        color: "#ca7113",
                        fontWeight: "bold",
                        textAlign: "center",
                        width: num.type === "dot" ? 11 : 33.3,
                      }}
                    >
                      {num.value}
                    </motion.span>
                  ))}
                </motion.div>

                {questions.map((question, i) => {
                  return (
                    <motion.div
                      key={`number-${i}`}
                      initial={"closed"}
                      // exit={"closed"}
                      animate="open"
                      variants={listVariants}
                      transition={{ duration: 1 }}
                      style={style}
                    >
                      {question.map((num, index) => (
                        <motion.span
                          key={`number-${i}-${index}`}
                          variants={itemVariants}
                          style={{
                            ...item,
                            visibility: num.visibility,
                            display: num.display,
                            width: num.type === "dot" ? 11 : 33.3,
                            color:
                              index === currentIndex
                                ? currentIndexColor
                                : item.color,
                          }}
                        >
                          {num.value}
                        </motion.span>
                      ))}
                    </motion.div>
                  );
                })}

                <LineSeparator />
                <motion.div
                  key="answers"
                  initial={"closed"}
                  // exit={"closed"}
                  animate="open"
                  variants={listVariants}
                  transition={{ duration: 2 }}
                  style={style}
                >
                  {answers.map((num, index) => (
                    <span
                      key={`answers-answer-${index}`}
                      ref={(ref) => (refs.current[`answer-${index}`] = ref)}
                    >
                      <motion.span
                        variants={itemVariants}
                        style={{
                          ...item,
                          visibility: num.visibility,
                          display: num.display,
                          width: num.type === "dot" ? 11 : 33.3,
                          borderBottom: "3px solid #2a3363",
                          color:
                            index === currentIndex
                              ? currentIndexColor
                              : item.color,
                        }}
                      >
                        {num.value}
                      </motion.span>
                    </span>
                  ))}
                </motion.div>
              </div>
            </AnswerWrapper>
          )}
        </BlackBoard>

        {explain && <Robot />}

        {explain ? (
          <Button
            icon="stop"
            circular
            color="red"
            onClick={() => {
              setExplain(false);
            }}
            style={{ position: "absolute", right: "4px", bottom: "6px" }}
          />
        ) : (
          <Button
            icon="play"
            circular
            color="blue"
            onClick={() => {
              if (window.speechSynthesis) {
                const utterance = new window.SpeechSynthesisUtterance();

                utterance.text = " ";
                utterance.onend = function () {
                  setExplain(true);
                };
                window.speechSynthesis.speak(utterance);
              }
            }}
            style={{ position: "absolute", right: "4px", bottom: "6px" }}
          />
        )}
      </ExplainWrapper>
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  font-size: 38px;
  font-weight: bold;
  box-shadow: 0px 0px 4px #949494;
  padding: 14px;
`;

const BlackBoard = styled.div`
  width: 100%;
  /* display: flex;

  justify-content: space-around;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  } */
`;

const MathDigit = styled.div`
  color: #13a4a8;
`;

const MathSign = styled.div`
  color: #561764;
  margin: 0px 6px;
`;

const LineSeparator = styled.div`
  height: 4px;
  background: #212321;
`;

const LanguageSelect = styled.div`
  height: 40px;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
`;

const ExplainWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 10px;
  border-radius: 4px;
  padding: 60px 0px 10px 0px;
  background: rgb(239 239 239);
  font-size: 38px;
  line-height: normal;
  margin-bottom: 0px;
  background: #393d3e;
  min-height: 360px;
`;

const AnswerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 4px;
  padding: 60px 0px 10px 0px;
  background: rgb(239 239 239);
  font-size: 38px;
  line-height: normal;
  margin-bottom: 0px;
  background: #393d3e;

  @media only screen and (max-width: 1000px) {
    padding: 0px;
    /* align-self: flex-end; */
    /* font-size: 28px; */
  }
`;

const EquationWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const Equation = styled.div`
  display: flex;
  font-size: 26px;
  font-weight: bold;
  box-shadow: 0px 0px 4px #949494;
  padding: 8px;
  border-radius: 4px;
  align-self: flex-start;
  background: white;
`;
