import React, { useState, useEffect } from "react";

import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Robot from "app/components/Robot";

import { setOffset } from "app/store/robot";

export default function MovingRobot(props) {
  const dispatch = useDispatch();
  const { style, onClick, moving = false } = props;

  const {
    type,
    animate: { x, y, scale },
    position: { top, right, bottom, left },
    dimensions: { width, height },
  } = useSelector((state) => state.robot);

  return (
    <motion.div
      drag
      width={width}
      height={height}
      onClick={() => !moving && onClick()}
      transition={{ duration: 2 }}
      animate={{ x, y, scale }}
      onDragEnd={(event, info) => {
        const { x, y } = info.offset;

        dispatch(
          setOffset({
            x: parseInt(x * 1000) / 1000,
            y: parseInt(y * 1000) / 1000,
          })
        );
      }}
      style={{
        top,
        right,
        bottom,
        left,
        zIndex: 10000,
        cursor: "pointer",
        position: "absolute",
      }}
    >
      <Robot />
    </motion.div>
  );
}
