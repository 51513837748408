import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "main",
  initialState: {
    options: {},
    video_src: null,
    top_bar_status: true,
  },
  reducers: {
    settingOptions: (state, action) => {
      state.options = action.payload;
    },
    playingVideo: (state, action) => {
      state.video_src = action.payload;
    },
    closingVideo: (state) => {
      state.video_src = null;
    },
    setTopBarStatus: (state, action) => {
      state.top_bar_status = action.payload;
    },

    closingVideo: (state) => {
      state.video_src = null;
    },
  },
});

export default slice.reducer;

const {
  settingOptions,
  playingVideo,
  closingVideo,
  setTopBarStatus,
} = slice.actions;

export const playVideo = (video_src, options = {}) => async (
  dispatch,
  getState
) => {
  dispatch(settingOptions(options));
  dispatch(playingVideo(video_src));
};

export const closeVideo = () => async (dispatch, getState) => {
  dispatch(closingVideo());
};

export const showTopBarStatus = (video_src) => async (dispatch, getState) => {
  dispatch(setTopBarStatus(true));
};

export const hideTopBarStatus = () => async (dispatch, getState) => {
  dispatch(setTopBarStatus(false));
};
