import I18n from "app/utils/i18n-js";

import { toastWarning, toastError } from "./toast";

export function handleAxiosError(error) {
  const { response } = error;

  if (!response) {
    handleServerError();
    throw { handled: true };
  }

  if (response.status === 403) {
    handleUnauthorized();
    response.handled = true;
  } else if (response.status === 404) {
    handleNotFound();
    response.handled = true;
  }

  throw response;
}

export function handleBaseResponseErrors(response) {
  const { status, data } = response;

  if (!data) {
    console.log(response);
    return;
  }

  const { errors } = data;

  if (status === 422 && errors.base) {
    errors.base.forEach((error) => {
      toastWarning(error, { position: "top-center" });
    });
  }
}

export function handleMeResponse(response) {
  if (response.handled) return;

  handleBaseResponseErrors(response);

  if (response.status === 422) {
    return response.data.errors;
  } else if (response.status === 401) {
    toastError(response.data.errors.base[0], { position: "top-center" });
  } else {
    console.log(response);
  }
}

export function handleActionResponse(response) {
  if (response.handled) return;

  handleBaseResponseErrors(response);

  if (response.status === 422) {
    return response.data.errors;
  } else if (response.status === 401) {
    toastError(response.data.errors.base[0], { position: "top-center" });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  } else {
    console.log(response);
  }
}

function handleServerError() {
  toastError(I18n.t("server_error"));
}

function handleUnauthorized() {
  toastError(I18n.t("unauthorized"));
}

function handleNotFound() {
  toastError(I18n.t("not_found"));
}
