import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "robot",
  initialState: {
    type: "idle",
    duration: 2,
    playing: false,
    bounds: { width: 0, height: 0 },
    animate: { x: 0, y: 0, scale: 1 },
    multiplier: { width: 1, height: 1 },
    dimensions: { height: 40, width: 40 },
    location: "bottom-left",
    // 2 combinations
    position: { bottom: 0, left: 0 },
  },
  reducers: {
    settingType: (state, action) => {
      state.type = action.payload;
    },

    settingScale: (state, action) => {
      state.animate = { ...state.animate, scale: action.payload };
    },
    settingPlaying: (state, action) => {
      state.playing = action.payload;
    },
    settingAnimate: (state, action) => {
      let animate;
      const { x, y } = action.payload;

      switch (state.location) {
        case "bottom-left":
          animate = { ...state.animate, x, y: -y };
          break;
        case "bottom-right":
          animate = { ...state.animate, x: -x, y: -y };
          break;
        case "top-left":
          animate = { ...state.animate, x, y };
          break;
        case "top-right":
          animate = { ...state.animate, x: -x, y };
          break;
        default:
      }

      state.animate = animate;
    },
    settingMultiplier: (state, action) => {
      const { animate } = state;
      const { width, height } = action.payload;
      state.multiplier = { width, height };
      // state.animate = {
      //   ...animate,
      //   x: animate.x * width,
      //   y: animate.y * height,
      //   scale: animate.scale * width,
      // };
    },
    settingOffset: (state, action) => {
      const { x, y, scale } = action.payload;
      const { animate } = state;

      state.animate = { ...animate, x: animate.x + x, y: animate.y + y };
    },
    settingPosition: (state, action) => {
      state.position = action.payload;
    },
    settingLocation: (state, action) => {
      let position = {};

      switch (action.payload) {
        case "bottom-left":
          position = { bottom: 0, left: 0 };
          break;
        case "bottom-right":
          position = { bottom: 0, right: 0 };
          break;
        case "top-left":
          position = { top: 0, left: 0 };
          break;
        case "top-right":
          position = { top: 0, right: 0 };
          break;
        default:
      }

      state.location = action.payload;
      state.position = position;
    },
    settingDimentions: (state, action) => {
      state.dimensions = { ...state.dimensions, ...action.payload };
    },
    // resettingDimentions: (state, action) => {
    //   state.type = initialState.type;
    //   state.position = initialState.position;
    //   state.dimensions = initialState.dimensions;
    // },
  },
});

export default slice.reducer;

const {
  settingType,
  settingScale,
  settingPlaying,
  settingAnimate,
  settingPosition,
  settingLocation,
  settingOffset,
  settingDimentions,
  settingMultiplier,
  // resettingDimentions,
} = slice.actions;

export const setType = (type = "idle") => async (dispatch) => {
  dispatch(settingType(type));
};

export const setScale = (scale = 1) => async (dispatch) => {
  dispatch(settingScale(scale));
};

export const setPlaying = (playing = false) => async (dispatch) => {
  dispatch(settingPlaying(playing));
};

export const setMultiplier = (multiplier = { width: 1, height: 1 }) => async (
  dispatch
) => {
  dispatch(settingMultiplier(multiplier));
};

export const setAnimate = (data = {}) => async (dispatch) => {
  dispatch(settingAnimate(data));
};

export const setPosition = (data = {}) => async (dispatch) => {
  dispatch(settingPosition(data));
};

export const setLocation = (data = {}) => async (dispatch) => {
  dispatch(settingLocation(data));
};

export const setOffset = (data = {}) => async (dispatch) => {
  dispatch(settingOffset(data));
};

export const setDimentions = (data = {}) => async (dispatch) => {
  dispatch(settingDimentions(data));
};

// export const reset = () => async (dispatch) => {
//   dispatch(resettingDimentions());
// };
