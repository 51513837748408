import React, { Suspense, useContext, createContext, useState } from "react";

import { Route, Routes } from "react-router-dom";

import Main from "app/screens/main";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
    </Routes>
  );
}
