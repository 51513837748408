import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Statistic, Container } from "semantic-ui-react";

import Game from "./Game";

import I18n from "app/utils/i18n-js";
import { getChessLearn } from "app/store/public/chess_learns";

export default function UserChessLearnLearns() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locale = "en";
  const slug = "learn_pawn_moves";

  const { chess_learn } = useSelector((state) => state.public_chess_learns);

  useEffect(() => {
    dispatch(getChessLearn(slug));
  }, [slug, locale]);

  if (!chess_learn) {
    return null;
  }

  return <Game chess_learn={chess_learn} />;
}
